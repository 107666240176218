import { useCallback, useEffect, useState } from 'react';

const key = 'parkedHere';
const dummyImage =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
export default function useLocalStorage() {
  const [savedFile, setSavedFile] = useState<string>(dummyImage);

  const loadFile = useCallback(() => {
    try {
      const imageFile = localStorage.getItem(key);

      // console.log('loadFile : imageFile : ', imageFile);

      if (imageFile) {
        setSavedFile(imageFile);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const saveFile = useCallback(
    (base64String: string) => {
      setSavedFile(dummyImage);
      try {
        localStorage.setItem(key, base64String);

        const bytes = new TextEncoder().encode(base64String).length;

        console.log('localStorage setItem : bytes : ', bytes);

        setTimeout(loadFile, 1000);
      } catch (error) {
        console.error(error);
      }
    },
    [loadFile]
  );

  useEffect(() => {
    loadFile();
  }, [loadFile]);

  return { saveFile, savedFile };
}
