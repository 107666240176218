/* eslint-disable no-unreachable */
import ParkedHere from './parked/ParkedHere';

export default function Home() {
  return (
    <>
      <ParkedHere></ParkedHere>
      <header style={{ overflow: 'auto' }}>
        <h1>Parking</h1>
        <h2>
          <div className="blind">
            Take a picture of where you parked.
            <br /> It is not saved to the photo album.
          </div>
        </h2>
        <div id="log"></div>
      </header>
    </>
  );
}

export class Logger {
  static log = (str: string) => {
    return;
    const logDiv = document.querySelector(`#log`);
    const p = document.createElement('P');
    p.textContent = str;
    logDiv?.appendChild(p);
  };
}
