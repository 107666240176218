import { useCallback, useEffect, useState } from 'react';
import { Logger } from '../Home';

const idealCamera = 1000;
const CAPTURE_OPTIONS = {
  audio: false,
  video: { facingMode: 'environment', width: { ideal: idealCamera }, height: { ideal: idealCamera } },
};

export function useUserMedia() {
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const [streamError, setStreamError] = useState<any>(null);
  const [cameraPermission, setCameraPermission] = useState<boolean>(false);

  const enableMediaStream = useCallback(() => {
    Logger.log('enableMediaStream :');

    navigator.mediaDevices
      .getUserMedia(CAPTURE_OPTIONS)
      .then((stream: MediaStream) => {
        Logger.log('enableMediaStream then :');
        streamSuccessHandler(stream);
      })
      .catch((error: any) => {
        Logger.log('enableMediaStream catch :');
        console.log('%cError:001 in navigator.mediaDevices.getUserMedia', 'color:red;font-size:17px', error);
        streamFailureHandler(error);
      });
  }, []);

  useEffect(() => {
    Logger.log('useUserMedia useEffect :');
    console.log('useUserMedia useEffect called');

    enableMediaStream();
  }, [enableMediaStream]);

  useEffect(() => {
    function visibilitychange() {
      Logger.log(`document.visibilityState : ${document.visibilityState}`);

      if (document.visibilityState === 'visible') {
        enableMediaStream();
      }
    }

    window.addEventListener('visibilitychange', visibilitychange);
    return () => {
      window.removeEventListener('visibilitychange', visibilitychange);
    };
  }, [enableMediaStream]);

  function streamSuccessHandler(stream: MediaStream) {
    Logger.log('streamSuccessHandler :');
    console.log('%cstreamSuccessHandler', 'color:red;font-size:20px');
    setCameraPermission(true);
    setMediaStream(stream);
  }

  function streamFailureHandler(error: any) {
    Logger.log('streamFailureHandler :');
    console.log('%cstreamFailureHandler', 'color:red;font-size:20px');
    console.log('error object0', error);
    setCameraPermission(false);
    setStreamError(error);
  }

  return [mediaStream, streamError, cameraPermission];
}
