import styles from './ParkedHere.module.scss';
import classNames from 'classnames';
import { useParkedHere } from './useParkedHere';

export default function ParkedHere() {
  const { videoRef, canvasRef, imgRef, handleCapture, savedFile } = useParkedHere();

  return (
    <>
      <canvas ref={canvasRef} style={{ display: 'none' }} />
      <div className={classNames(styles.wrap)}>
        <section>
          <img ref={imgRef} src={savedFile || undefined} alt="test" />
        </section>
        <section className={classNames(styles.capture)}>
          <video
            ref={videoRef}
            onCanPlay={() => {
              videoRef.current.play();
            }}
            playsInline
            muted
          />
          <div className={classNames(styles.border)}></div>
          <button className={classNames(styles.shotbutton)} onClick={handleCapture}></button>
        </section>
      </div>
    </>
  );
}
