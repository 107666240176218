import { useCallback, useEffect, useRef } from 'react';
import useLocalStorage from './useLocalStorage';
import { useUserMedia } from './useUserMedia';

export function useParkedHere() {
  const videoRef = useRef<any>(null);
  const canvasRef = useRef<any>(null);
  const imgRef = useRef<any>(null);

  const [mediaStream, streamError, cameraPermission] = useUserMedia();
  const { saveFile, savedFile } = useLocalStorage();

  useEffect(() => {
    console.log('%cchanged mediaStream : ', 'color:blue', mediaStream);
    if (mediaStream && videoRef.current) {
      console.log('assign mediaStream', mediaStream);
      videoRef.current.setAttribute('autoPlay', 'true');
      videoRef.current.srcObject = mediaStream;
    }
  }, [mediaStream]);

  //   useEffect(() => {
  //     function visibilitychange() {
  //       if (document.visibilityState === 'visible') {
  //         console.log(' mediaStream : ', 'color:blue', mediaStream);
  //         videoRef.current.srcObject = mediaStream;
  //       }
  //     }

  //     window.addEventListener('visibilitychange', visibilitychange);
  //     return () => {
  //       window.removeEventListener('visibilitychange', visibilitychange);
  //     };
  //   }, [mediaStream]);

  useEffect(() => {
    if (streamError) console.log('Error in mdediaStream', streamError);
  }, [streamError]);

  const handleCapture = useCallback(() => {
    const video = videoRef.current;
    const canvas = canvasRef.current;

    const showArea = video.getBoundingClientRect();

    const showAreaWidth = showArea.width;
    const showAreaHeight = showArea.height;

    const showAreaRatio = showAreaHeight / showAreaWidth;
    const videoRatio = video.videoHeight / video.videoWidth;

    let top = 0;
    let left = 0;

    if (showAreaRatio < videoRatio) {
      // 영상의 세로가 더 큰 경우
      canvas.width = video.videoWidth;
      canvas.height = video.videoWidth * showAreaRatio;
      top = (video.videoHeight - canvas.height) / 2;
    } else {
      // 영상의 가로가 더 큰 경우
      canvas.height = video.videoHeight;
      canvas.width = video.videoHeight / showAreaRatio;
      left = (video.videoWidth - canvas.width) / 2;
    }

    const videoWidth = (videoRef.current as HTMLVideoElement).videoWidth;
    const videoHeight = (videoRef.current as HTMLVideoElement).videoHeight;
    console.log('videoWidth : ', videoWidth);
    console.log('videoHeight : ', videoHeight);

    console.log('canvas.height : ', canvas.height);
    console.log('canvas.width : ', canvas.width);

    canvas.getContext('2d').drawImage(video, -left, -top);

    const base64String = canvas.toDataURL('image/jpeg', 1);
    console.log('sava base64String : ');

    saveFile(base64String);
  }, [saveFile]);

  console.log('reReder :  cameraPermission : ', cameraPermission);

  return { videoRef, canvasRef, imgRef, handleCapture, savedFile };
}
